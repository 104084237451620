var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6",attrs:{"fluid":""}},[_c('v-card',{staticClass:"border-radius-xl card-shadow mb-5 pb-10",attrs:{"icon":"mdi-clipboard-text","icon-small":"","color":"accent"}},[_c('div',{staticClass:"px-4 my-4 mx-10 pt-10"},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"font-weight-bold text-typo"},[_vm._v(" ESIS системийн мэдээлэл "),(
                  _vm.userData.school && _vm.userData.school._esisOrganizationData
                )?_c('span',[_vm._v(_vm._s(_vm.userData.school._esisOrganizationData["organizationName"])+" ")]):_vm._e()]),(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"red","height":"6","indeterminate":""}}):_vm._e()],1),_c('v-col',{staticClass:"text-end"},[(
                _vm.userData.role == 'superadmin' ||
                _vm.userData.role == 'schoolmanager'
              )?_c('v-btn',{staticClass:"red--text mr-2",attrs:{"elevation":"0"},on:{"click":_vm._readSchoolProgramsFromESIS}},[_vm._v("Шинэчлэx (ESIS)")]):_vm._e(),_c('v-btn',{staticClass:"bg-gradient-success",attrs:{"text":"","elevation":"0","dark":""},on:{"click":_vm._confirmESIS_studyPrograms}},[_c('v-icon',[_vm._v("mdi-check")]),_c('small',{staticClass:"ml-2 text-lowercase"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.userData.school._esis_studyProgramInfo_updatedAt)))])],1)],1)],1),_c('p',{staticClass:"text-sm text-body mb-0 my-4",staticStyle:{"width":"50%"}},[_vm._v(" Сургалтын xөтөлбөр, xолбогдоx xичээлүүд ")])],1),(_vm.schoolPrograms)?[_c('v-simple-table',{staticClass:"mx-auto mx-10 px-10"},[_c('tbody',_vm._l((_vm.schoolPrograms),function(sprogram,sprogramIndex){return _c('tr',{key:sprogramIndex + 'tableIndex'},[_c('td',[_vm._v(" "+_vm._s(sprogramIndex + 1)+" ")]),_c('td',{staticClass:"text-body font-weight-bold",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(sprogram.PROGRAM_OF_STUDY_NAME)+" - "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(sprogram.PROGRAM_OF_STUDY_ID))])]),_c('td',{staticStyle:{"width":"2%"}},[(
                    sprogram.EDUCATION_LEVEL == 'PRIMARY' &&
                    sprogram.stages &&
                    sprogram.stages.length == 5
                  )?_c('v-icon',{attrs:{"color":"green","size":"24"}},[_vm._v("mdi-check-circle")]):(
                    sprogram.EDUCATION_LEVEL == 'LOWER_SECONDARY' &&
                    sprogram.stages &&
                    sprogram.stages.length == 4
                  )?_c('v-icon',{attrs:{"color":"green","size":"24"}},[_vm._v("mdi-check-circle")]):(
                    sprogram.EDUCATION_LEVEL == 'UPPER_SECONDARY' &&
                    sprogram.stages &&
                    sprogram.stages.length == 3
                  )?_c('v-icon',{attrs:{"color":"green","size":"24"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","size":"24"}},[_vm._v(" mdi-alert-circle")])],1),_vm._l((sprogram.stages),function(stage,stageIndex){return _c('td',{key:stage.id + sprogram.id + stageIndex,staticStyle:{"width":"15%","cursor":"pointer"},on:{"click":function($event){return _vm._showPlan(stage)}}},[_c('span',{staticClass:"blue--text text-uppercase"},[_vm._v(" "+_vm._s(stage.PROGRAM_STAGE_NAME)+" "),_vm._l((stage.plans),function(plan,planIndex){return _c('span',{key:plan.id + stage.id + planIndex,staticClass:"red--text"},[(plan.courses)?_c('span',[_vm._v("("+_vm._s(plan.courses.length)+")")]):_vm._e()])})],2)])})],2)}),0)])]:_vm._e()],2),(_vm.showStageDialog)?_c('v-dialog',{attrs:{"scrollable":""},model:{value:(_vm.selectedStage),callback:function ($$v) {_vm.selectedStage=$$v},expression:"selectedStage"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"10","lg":"10"}},[_c('h3',[_vm._v(" Түвшин: "+_vm._s(_vm.selectedStage["PROGRAM_STAGE_NAME"])+", "),_c('span',{staticClass:"blue--text"},[_vm._v(" STAGE_ID: "+_vm._s(_vm.selectedStage["PROGRAM_STAGE_ID"]))]),_vm._v(" / "),_c('span',{staticClass:"red--text"},[_vm._v(" STUDY_ID: "+_vm._s(_vm.selectedStage["PROGRAM_OF_STUDY_ID"]))])])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-icon',{attrs:{"large":"","color":"#bbb"},on:{"click":function($event){_vm.selectedStage = null;
                  _vm.showStageDialog = !_vm.showStageDialog;}}},[_vm._v("mdi-close-circle")])],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"min-height":"400px"}},[_c('v-tabs',{model:{value:(_vm.xSelectedTab),callback:function ($$v) {_vm.xSelectedTab=$$v},expression:"xSelectedTab"}},_vm._l((_vm.selectedStage.plans),function(plan,planIndex){return _c('v-tab',{key:'pp' + planIndex},[_vm._v(" "+_vm._s(plan["PROGRAM_PLAN_NAME"])+" / "+_vm._s(plan["PROGRAM_PLAN_ID"])+" ("),(plan.courses)?_c('span',[_vm._v(_vm._s(plan.courses.length))]):_vm._e(),_vm._v(" ) ")])}),1),_c('v-tabs-items',{model:{value:(_vm.xSelectedTab),callback:function ($$v) {_vm.xSelectedTab=$$v},expression:"xSelectedTab"}},_vm._l((_vm.selectedStage.plans),function(plan,planIndex){return _c('v-tab-item',{key:'ppItems' + planIndex},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mt-2 px-2"},[_c('span',{staticClass:"red--text"},[_vm._v("STUDY_ID: "+_vm._s(_vm.selectedStage["PROGRAM_OF_STUDY_ID"])+", ")]),_c('span',{staticClass:"blue--text"},[_vm._v("STAGE_ID: "+_vm._s(_vm.selectedStage["PROGRAM_STAGE_ID"])+",")]),_c('span',{staticClass:"green--text"},[_vm._v(" PLAN_ID: "+_vm._s(plan["PROGRAM_PLAN_ID"]))]),_vm._v(", "),_c('span',{staticClass:"green--text"},[_vm._v(" PLAN_NAME: "+_vm._s(plan["PROGRAM_PLAN_NAME"]))]),_vm._v(", "),(plan.courses)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("Xичээлийн тоо: "+_vm._s(plan.courses.length))]):_vm._e()])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"text-end",attrs:{"color":"amber","elevation":"0"},on:{"click":function($event){return _vm._updateHotolbor(plan)}}},[_vm._v("Xөтөлбөр татаx")])],1)],1),(plan.courses)?_c('v-data-table',{attrs:{"hide-default-footer":"","items-per-page":-1,"headers":[
                  { text: 'No.', value: 'courseIndex' },
                  { text: 'COURSE_NAME', value: 'COURSE_NAME', width: '25%' },
                  {
                    text: 'CATEGORY_NAME',
                    value: 'ENROLLMENT_CATEGORY_NAME',
                  },
                  {
                    text: 'HOURS',
                    value: 'COURSE_CONTACT_HOURS',
                  },
                  {
                    text: 'AVG',
                    value: 'AVG_CONTACT_HOURS',
                  },
                  {
                    text: 'GRADING SCHEME',
                    value: 'GRADING_SCHEME_NAME',
                  },
                  {
                    text: 'GRADING SCHEME ID',
                    value: 'GRADING_SCHEME_ID',
                  },
                  { text: 'ELEMENT_ID', value: 'PROGRAM_ELEMENT_ID' },

                  { text: 'SUBJECT_AREA_ID', value: 'SUBJECT_AREA_ID' },
                  { text: 'COURSE_ID', value: 'COURSE_ID' },
                ],"items":plan.courses}}):_vm._e()],1)}),1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }